import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

const Chart = (props) => {
  
  const myLabels = props.labels;
  const myData = props.dataState;
  let myScore = myData.length === 0 ? 0 : myData[myData.length -1];
  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Score: ${myScore}%` ,
      },
    },
    scales: {
      y: {
        afterDataLimits: (scale) => {
          scale.max=100;
          scale.min=0;
        },
        ticks: {
          stepSize: 20
        }
      },
    },
  };
  const data = {
    labels: myLabels,
    datasets: [
      {
        data: myData,
        borderColor: 'rgb(242, 169, 0)',
        backgroundColor: 'rgba(242, 169, 132, 0.5)',
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default Chart;