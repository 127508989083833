import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import quotes from './components/Quotes';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import theme from './theme';
import Chart from './components/Chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';


const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
};


const App = () => {

  const [count, setCount] = useState(0);
  const [list, setList] = useState([{"quote":"", isSatoshi: true}]);
  const [history, setHistory] = useState([]);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [labels, setLabels] = useState([0,1,2,3,4,5,6]);
  const [result, setResult] = useState("");
  const [hide, setHide] = useState({ display: "none" });
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [final, setFinal] = useState({ display: "none" });

  let finalScore = history[history.length -1];
  const incrementCount = () => setCount(count + 1);

  const updateScore = (answer) => {
    let currentCount = count + 1;
    let rights = rightAnswers;

    if (labels.length === count){
      let myLabels = labels;
      myLabels.push(count);
      setLabels(myLabels);
    }
    
    if (answer === true) {
      let newRightAnswers = rights + 1;
      setRightAnswers(newRightAnswers);
      let myScore = Math.round((newRightAnswers / currentCount) * 100);
      let myHistory = history;
      myHistory.push(myScore);
      setHistory(myHistory);
    }
    if (answer === false) {
      let myScore = Math.round((rightAnswers / currentCount) * 100);
      let myHistory = history;
      myHistory.push(myScore);
      setHistory(myHistory);
    }
  };

  useEffect(() =>{
    let q = shuffle(quotes);
    setList(q);
  }, []);

  const wrongSatoshiSaidIt = () => { 
    if (count < 16) {
      setResult("Wrong! Satoshi said it. ");
      setHide({ display: "block", marginLeft: "7px" });
      setButtonsDisabled(true);
      updateScore(false);
    }
    if (count === 16) {
      setFinal({ display: "block", marginLeft: "7px" });
      setButtonsDisabled(true);
    }
  };
  const wrongVitalikSaidIt = () => { 
    if (count < 16) {
      setResult("Wrong! Vitalik said it. ");
      setHide({ display: "block", marginLeft: "7px" }); 
      setButtonsDisabled(true);
      updateScore(false);
    }
    if (count === 16) {
      setButtonsDisabled(true);
      setFinal({ display: "block", marginLeft: "7px" });
    }
  };
  const correctSatoshiSaidIt = () => { 
    if (count < 16) {
      setResult("Correct! Satoshi said it. ");
      setHide({ display: "block", marginLeft: "7px" });
      setButtonsDisabled(true);
      updateScore(true);
    }
    if (count === 16) {
      setFinal({ display: "block", marginLeft: "7px" });
      setButtonsDisabled(true);
    }
  };
  const correctVitalikSaidIt = () => { 
    if (count < 16) {
      setResult("Correct! Vitalik said it. ");
      setHide({ display: "block", marginLeft: "7px" });
      setButtonsDisabled(true);
      updateScore(true);
    }
    if (count === 16) {
      setFinal({ display: "block", marginLeft: "7px" });
      setButtonsDisabled(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          position: 'relative',
          padding: '15px',
        }}
      >
        <Typography m={2} variant="h2" 
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            color: 'neutral.satoshi',
            fontWeight: 'bold',
            fontStyle: 'italic',
          }}
        >
          Satoshi or Vitalik?
        </Typography>

        <Grid container 
          sx={{
            mt: '150px',
          }}
        >
          <Grid item md={4} 
            order={{
              xs: 3,
              md: 1
            }}
            sx={{
              maxHeight: '400px',
              marginBottom: '150px',
            }}
          >
            <Chart dataState={history} labels={labels} />

          </Grid>

          <Grid item container md={8} 
            sx={{
              position: 'relative',
              mb: 'auto',
              padding: '10px',
            }}
            order={{
              xs: 1,
              md: 2,
            }}
          >
            <Grid item container
              sx={{
                position: 'relative',
              }}
            >
              <Icon className="fa-solid fa-quote-left" 
                sx={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                }}
              >
              </Icon>
              <Typography variant='h5' 
                sx={{
                  padding: '35px',
                  paddingBottom: '100px',
                }}
              >
                { list[count].quote }
              </Typography>
              <Icon className="fa-solid fa-quote-right" 
                sx={{
                  position: 'absolute',
                  bottom: '50px',
                  right: '5px',
                }}
              >
              </Icon>
            </Grid>

            <Grid item container
              sx={{
                justifyContent: 'center',
              }}
            >
              <Button variant='contained' size='large' disabled={buttonsDisabled} 
                sx={{
                  mx: '10px',
                }}
                onClick={ () => { 
                  return (list[count].isSatoshi ? correctSatoshiSaidIt() : wrongVitalikSaidIt() );
                  }
                }
              >
                Satoshi
              </Button>
              <Button variant='contained' size='large' disabled={buttonsDisabled} 
                sx={{
                  mx: '10px',
                }}
                onClick={ () => { 
                  return (list[count].isSatoshi ? wrongSatoshiSaidIt() : correctVitalikSaidIt() );
                  }
                }
              >
              Vitalik
              </Button>
            </Grid>
            <Grid item container 
              sx={{
                margin: '10px',
              }}
            >
              <Typography sx={ hide } variant='h4'>{ result }</Typography>
              <Button variant='contained' size='large' sx={ hide }
                onClick={ () => { 
                  incrementCount();
                  setHide({ display: 'none' });
                  setButtonsDisabled(false);
                }}
              >
                Another?
              </Button>
              <Typography sx={ final } variant='h4'>The End! Final score: &nbsp; { finalScore }% </Typography>
              <Button variant='contained' size='large' sx={ final }
                onClick={ () => { 
                  window.location.reload(false);
                }}
              >
                Play again?
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: 'absolute',
            left: '18px',
            bottom: '18px',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle2"
            sx={{
              color: 'neutral.foot',
            }}
          >
            By Henry Magram & Ian Campbell 
          </Typography>
          <Typography variant="subtitle2"
            sx={{
              color: 'neutral.foot',
            }}
          >
            Copyright &copy; 2024 &nbsp; 
            <Link href="https://www.twitter.com/henrymagram" 
              target="_blank" rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
