import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    neutral: {
      satoshi: '#f2a900',
      foot: '#A8A095',
    },
    mode: 'dark',
    background: {
      default: '#000000'
    },
  },
  typography: {
    fontFamily: [
      'Ubuntu',
    ],
  },
});

export default theme;