
const quotes = [
  {
    "quote": "If you don’t believe it or don’t get it, I don’t have the time to try to convince you, sorry.",
    "isSatoshi": 1
  },
  {
    "quote": "In a few decades when the reward gets too small, the transaction fee will become the main compensation for nodes. I’m sure that in 20 years there will either be very large transaction volume or no volume.",
    "isSatoshi": 1
  },
  {
    "quote": "Bitcoin isn’t currently practical for very small micropayments.  Not for things like pay per search or per page view without an aggregating mechanism, not things needing to pay less than 0.01.  The dust spam limit is a first try at intentionally trying to prevent overly small micropayments like that.",
    "isSatoshi": 1
  },
  {
    "quote": "When someone tries to buy all the world’s supply of a scarce asset, the more they buy the higher the price goes. At some point, it gets too expensive for them to buy any more. It’s great for the people who owned it beforehand because they get to sell it to the corner at crazy high prices.",
    "isSatoshi": 1
  },
  {
    "quote": "The root problem with conventional currency is all the trust that’s required to make it work. The central bank must be trusted not to debase the currency, but the history of fiat currencies is full of breaches of that trust.",
    "isSatoshi": 1
  },
  {
    "quote": "It has the potential for a positive feedback loop; as users increase, the value goes up, which could attract more users to take advantage of the increasing value.",
    "isSatoshi": 1
  },
  {
    "quote": "Yes, there will be natural deflation due to payment mistakes and lost data. Coin creation will eventually get slow enough that it is exceeded by natural deflation and we’ll have net deflation.",
    "isSatoshi": 1
  },
  {
    "quote": "In later years, when new coin generation is a small percentage of the existing supply, market price will dictate the cost of production more than the other way around.",
    "isSatoshi": 1
  },
  {
    "quote": "If coins are to be distributed to random Bitcoin addresses, then we will have miners calculating trillions of hashes generating addresses.",
    "isSatoshi": 0
  },
  {
    "quote": "Cryptographic proof-of-work, although inefficient, is the only currently available practical means of solving the problem while maintaining the anonymity of the Internet, and Bitcoin and Bitcoin’s ideological parent, Hashcash, an anti-spam system which required email senders to solve the reverse hash problem, recognize this.",
    "isSatoshi": 0
  },
  {
    "quote": "In the long term, the size of the network is driven by two things and only two things: the price of a Bitcoin and the block creation reward (set reward plus transaction fees).",
    "isSatoshi": 0
  },
  {
    "quote": "There are now 8.3 trillion US dollars in the world (both physical and virtual), so if Bitcoin were to take control of all that, its (equivalent, since in such a world USD would likely no longer exist) price would be $8.3 trillion / 21 million, or $3.95 million USD per BTC.",
    "isSatoshi": 0
  },
  {
    "quote": "An important property of a blockchain that users really value is permanence.",
    "isSatoshi": 0
  },
  {
    "quote": "For a blockchain to be decentralized, it's crucially important for regular users to be able to run a node, and to have a culture where running nodes is a common activity.",
    "isSatoshi": 0
  },
  {
    "quote": "The Core argument is that miners only have a limited role in the Bitcoin system, to secure the ordering of transactions, and they should NOT have the power to determine anything else, including block size limits and other block validity rules.",
    "isSatoshi": 0
  },
  {
    "quote": "Currently, formal democratic input into organizations (governmental or corporate) tends to be limited to a single vote once every 1-6 years. This effectively means that each voter is only putting less than one bit of input into the system each year. Perhaps in large part as a result of this, decentralized decision-making in our society is heavily bifurcated into two extremes: pure democracy and pure markets.",
    "isSatoshi": 0
  },
  {
    "quote": "Legitimacy is a phenomenon that arises naturally in coordination games. If you're not in a coordination game, there's no reason to act according to your expectation of how other people will act, and so legitimacy is not important. But as we have seen, coordination games are everywhere in society, and so legitimacy turns out to be quite important indeed. In almost any environment with coordination games that exists for long enough, there inevitably emerge some mechanisms that can choose which decision to take.",
    "isSatoshi": 0
  }
]

export default quotes;